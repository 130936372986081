.main-div-investor{
    padding: 20px;
}

.main-heading{
    text-align: center;
}

.main-text{
    text-align:center;
}